<template>
  <div class="w-full flex flex-col pt-3">
    <div class="flex flex-row justify-between items-center px-5">
      <span class="text-2xl font-medium">
        Messages
      </span>
      <div
        class="text-2xl text-black cursor-pointer pt-1.5 md:hidden"
        @click="togglePanel()">
        <icon icon="majesticons:close" />
      </div>
    </div>
    <div class="w-full text-center border-b border-gray-200 px-5 pb-2 pt-1">
      <search-box-with-tag
        v-model="filterGuest"
        :disabled="loading"
        @on-search="onSearch()" />
      <div class="w-full flex flex-row justify-between items-center">
        <span class="text-sm">
          จำนวนคนที่รอตอบ : {{ unReadCount }} คน
        </span>
        <div
          class="text-purple-500 hover:text-purple-300 duration-300 cursor-pointer uppercase"
          @click="changeMode()">
          {{ mode }}
        </div>
      </div>
    </div>
    <div
      class="flex flex-col transform duration-300"
      style="height: calc(100vh - 208px)">
      <div
        class="flex flex-row items-center gap-x-2 cursor-pointer p-2 duration-300"
        :class="channel === 'all' ? 'text-black' : 'channel-text-color'"
        style="width: fit-content; order: 1"
        @click="changeChannel('all')">
        <icon
          icon="solar:archive-linear"
          class="text-lg" />
        <span class="text-base font-light">
          ALL CUSTOMERS
        </span>
        <div
          class="text-sm transform"
          :class="channel === 'all' ? 'rotate-180' : 'rotate-0'">
          <icon icon="solar:alt-arrow-down-bold" />
        </div>
      </div>
      <div
        class="flex flex-row items-center gap-x-2 cursor-pointer p-2 duration-300"
        :class="channel === 'LINE' ? 'text-black' : 'channel-text-color'"
        style="width: fit-content; order: 3"
        @click="changeChannel('LINE')">
        <icon
          icon="solar:chat-round-line-linear"
          class="text-lg"
          style="color: #02C755" />
        <span class="text-base font-light">
          LINE CUSTOMERS
        </span>
        <div
          class="text-sm transform"
          :class="channel === 'LINE' ? 'rotate-180' : 'rotate-0'">
          <icon icon="solar:alt-arrow-down-bold" />
        </div>
      </div>
      <div
        class="flex flex-row items-center gap-x-2 cursor-pointer p-2 duration-300"
        :class="channel === 'facebook' ? 'text-black' : 'channel-text-color'"
        style="width: fit-content; order: 5"
        @click="changeChannel('facebook')">
        <icon
          icon="formkit:facebook"
          class="text-lg"
          style="color: #4167B2" />
        <span class="text-base font-light">
          FACEBOOK CUSTOMERS
        </span>
        <div
          class="text-sm transform"
          :class="channel === 'facebook' ? 'rotate-180' : 'rotate-0'">
          <icon icon="solar:alt-arrow-down-bold" />
        </div>
      </div>
      <div
        v-show="!loading || page > 1"
        ref="chatList"
        class="guest-list"
        :style="{
          order: checkOrder()
        }">
        <div
          v-for="(item, i) in chatItems"
          :key="i"
          class="cursor-pointer duration-300 px-5"
          :class="{
            'border border-gray-200': (selectedGuest && selectedGuest.id === item.id)
          }"
          :style="{
            'background-color': (selectedGuest && selectedGuest.id === item.id) ? '#eeeeee' : '#FFFFFF'
          }"
          @click="selectGuest(item)">
          <chat-item :item="item" />
        </div>
      </div>
      <div
        v-show="loading && page <= 1"
        class="flex justify-center items-center text-xl px-5"
        :style="{
          order: checkOrder()
        }">
        <loading />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import SocialGuestProvider from '../../../resources/social-guest.provider'
import AdminProvider from '../../../resources/admin.provider'
import SearchBoxWithTag from '../../../components/SearchBoxWithTag.vue'
import ChatItem from './ChatItem.vue'

const SocialGuestService = new SocialGuestProvider()
const AdminService = new AdminProvider()

export default {
  components: { SearchBoxWithTag, ChatItem },
  data () {
    return ({
      loading: true,
      page: 0,
      perPage: 15,
      totalPage: 1,
      unReadCount: 0,
      filterGuest: {
        text: '',
        tags: []
      },
      chatItems: [],
      mode: 'bot',
      channel: 'all'
    })
  },
  computed: {
    selectedGuest () {
      return this.$store.getters['Guest/selectedGuest']
    }
  },
  mounted () {
    this.sockets.subscribe('NEW_MESSAGE', (data) => {
      if (data?.guest?.chatMode === this.mode && (data?.guest?.channel === this.channel || this.channel === 'all')) {
        const index = this.chatItems.findIndex((item) => item.id === data.guest.id)
        if (index !== -1) {
          this.chatItems.splice(index, 1)
        }
        this.chatItems.unshift(data.guest)
      }
    })

    this.sockets.subscribe('ADMIN_EVENT_UPDATE', (data) => {
      if (data?.guest?.chatMode !== this.mode && data?.guest?.channel !== this.channel) {
        const index = this.chatItems.findIndex((item) => item.id === data?.guest?.id)
        if (index !== -1) {
          this.chatItems.splice(index, 1)
        }
      } else {
        if (!this.chatItems.length) {
          return
        }

        const index = this.chatItems.findIndex((item) => item.id === data?.guest?.id)
        if (index === -1) {
          this.chatItems.unshift({
            ...data.guest,
            reading: data.admin
          })
        } else {
          const otherIndex = this.chatItems.findIndex((item) => item?.reading?.id === data?.admin?.id && item?.id !== data?.guest?.id)

          if (otherIndex !== -1) {
            const tmpItem = { ...this.chatItems[otherIndex] }
            delete tmpItem.reading
            this.chatItems.splice(otherIndex, 1, { ...tmpItem })
          }

          this.chatItems.splice(index, 1, {
            ...data.guest,
            reading: data.admin
          })
        }
      }
    })

    this.$refs.chatList.addEventListener('scroll', this.handleScroll)
    this.initGuest()
  },
  beforeDestroy () {
    this.$refs.chatList.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      setSelectedGuest: 'Guest/setSelectedGuest'
    }),
    handleScroll (event) {
      const scrollY = event.target.scrollHeight - event.target.clientHeight
      if (event.target.scrollTop >= scrollY - 50 && !this.loading) {
        this.initGuest()
      }
    },
    async initGuest () {
      try {
        this.loading = true

        const { data: admins } = await AdminService.getOnlineAdmin()

        if (this.page < this.totalPage) {
          this.page++

          const { data } = await SocialGuestService.getGuest({
            page: this.page,
            limit: this.perPage,
            search: this.filterGuest.text,
            tags: this.filterGuest.tags,
            chatMode: this.mode,
            channel: (this.channel === 'all') ? null : this.channel
          })

          this.totalPage = data.pages
          this.chatItems.push(...data.results)
        }

        const { data } = await SocialGuestService.getUnReadCount({
          search: this.filterGuest.text,
          tags: this.filterGuest.tags,
          chatMode: this.mode,
          channel: (this.channel === 'all') ? null : this.channel
        })
        this.unReadCount = data

        for (const payload of admins) {
          const index = this.chatItems.findIndex((item) => item.id === payload.reading?.id)

          if (index !== -1) {
            const tmpItem = { ...this.chatItems[index] }
            this.chatItems.splice(index, 1, {
              ...tmpItem,
              reading: payload.admin
            })
          }
        }
      } catch (error) {
        console.error('initGuest', error)
      } finally {
        this.loading = false
      }
    },
    onSearch () {
      this.page = 0
      this.perPage = 15
      this.totalPage = 1
      this.chatItems = []
      this.initGuest()
    },
    async selectGuest (guest) {
      const uid = this.$route.params?.uid || null

      if (uid !== guest.uid) {
        this.setSelectedGuest(guest)
        this.$router.push(`/t/${guest.uid}`)
      }
    },
    checkOrder () {
      switch (this.channel) {
        case 'all':
          return 2
        case 'LINE':
          return 4
        case 'facebook':
          return 6
        default: return 0
      }
    },
    changeChannel (channel) {
      if (channel !== this.channel) {
        this.page = 0
        this.perPage = 15
        this.totalPage = 1
        this.chatItems = []
        this.channel = channel
        this.initGuest()
      }
    },
    changeMode () {
      this.page = 0
      this.perPage = 15
      this.totalPage = 1
      this.chatItems = []
      this.mode = this.mode === 'bot' ? 'chat' : 'bot'
      this.initGuest()
    },
    togglePanel () {
      this.$emit('toggle-panel')
    }
  }
}
</script>

<style scoped>
.guest-list {
  height: calc(100vh - 328px);
  overflow: auto;
}
.channel-text-color {
  color: #c2c2c2;
}
.channel-text-color:hover {
  color: #000;
}
.mode-container {
  min-height: 2.5rem;
  height: 2.5rem;
  width: 100%;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
</style>
