<template>
  <div
    id="chat"
    class="relative flex flex-row h-screen">
    <div
      class="fixed md:relative z-20 h-full bg-white border-r border-gray-200 duration-500"
      :class="(showLeftPanel) ? 'left-panel' : 'close-panel'">
      <div
        class="
          absolute
          z-20
          flex
          justify-center
          items-center
          -right-2
          top-1
          transform
          translate-x-full
          translate-y-3
          cursor-pointer
          duration-300
          text-gray-400
          hover:text-black
        "
        @click="toggleLeftPanel()">
        <icon
          icon="ic:round-menu"
          class="text-2xl" />
      </div>
      <div
        v-if="showLeftPanel || loadingLeftPanel"
        class="chat-list-container"
        :class="(showLeftPanel) ? 'opacity-100' : 'opacity-0'">
        <chat-list
          class="overflow-y-auto"
          @toggle-panel="toggleLeftPanel()" />
        <div class="w-full h-12 flex flex-row justify-center items-center">
          <img
            v-if="isNull(user, 'picture')"
            :src="user.picture"
            alt="Profile Picture"
            class="w-8 h-8 mr-2 rounded-full">
          <span
            v-if="isNull(user, 'name')"
            class="mr-2">
            {{ user.name }}
          </span>
          <div
            class="cursor-pointer text-2xl"
            @click="logout()">
            <icon
              icon="solar:logout-linear"
              color="#000" />
          </div>
        </div>
      </div>
    </div>
    <div class="relative w-full h-full">
      <router-view />
    </div>
  </div>
</template>

<script>
import { getAuthDecode } from '../../utils/auth.utils'
import ChatList from './components/ChatList.vue'
import AdminProvider from '@/resources/admin.provider'

const AdminService = new AdminProvider()

export default {
  components: { ChatList },
  data () {
    return {
      showLeftPanel: true,
      loadingLeftPanel: false,
      user: null
    }
  },
  created () {
    this.onlineAdmin()
    window.addEventListener('beforeunload', (e) => {
      this.offlineAdmin()
      return e
    })
  },
  mounted () {
    this.user = getAuthDecode()
  },
  methods: {
    offlineAdmin () {
      AdminService.offLineAdmin()
    },
    onlineAdmin () {
      AdminService.onlineAdmin()
    },
    toggleLeftPanel () {
      if (!this.loadingLeftPanel) {
        this.loadingLeftPanel = true
        this.showLeftPanel = !this.showLeftPanel
        setTimeout(() => {
          this.loadingLeftPanel = false
        }, 500)
      }
    },
    isNull (obj, target) {
      return obj && obj[target]
    },
    logout () {
      this.$router.push({ name: 'Logout' })
    }
  }
}
</script>

<style scoped>
.turn-left {
  transform: rotateZ(180deg);
}
.turn-right {
  transform: rotateZ(0deg);
}
.left-panel {
  width: 400px;
}
.close-panel {
  width: 0;
}
.chat-list-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition-duration: .5s;
}

@media (max-width: 767px) {
  .left-panel {
    width: 100vw;
  }
}
</style>
