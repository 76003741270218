import HttpRequest from './http-request'
import { getAuthToken } from '../utils/auth.utils'

class AdminProvider extends HttpRequest {
  constructor () {
    super(process.env.VUE_APP_CHAT_API)
  }

  getOnlineAdmin () {
    this.setHeader(getAuthToken())
    return this.get('/activities/online')
  }

  onlineAdmin () {
    this.setHeader(getAuthToken())
    return this.post('/activities/online')
  }

  offLineAdmin () {
    this.setHeader(getAuthToken())
    return this.post('/activities/offline')
  }
}

export default AdminProvider
