<template>
  <div>
    <form
      class="flex flex-col"
      @submit.prevent="onSearch()">
      <div class="flex flex-row flex-wrap mt-1 w-full self-center">
        <div
          v-for="(tag, t) in filter.tags"
          :key="t"
          class="relative flex flex-row justify-center items-center rounded bg-yellow-200 text-xs mr-1 mt-1 p-1">
          <span>{{ tag }}</span>
          <div
            class="text-lg text-black cursor-pointer ml-1"
            @click="removeSelectTag(t)">
            <icon icon="majesticons:close" />
          </div>
        </div>
      </div>
      <div class="grid grid-cols-10 gap-x-2 my-3">
        <div
          class="col-span-9 flex flex-row mr-1 w-full relative px-3 py-3 rounded-lg"
          style="background-color: #f6f6f6">
          <input
            v-model="filter.text"
            type="text"
            placeholder="Search..."
            :disabled="disabled"
            class="border-none outline-none rounded text-sm w-full px-1"
            style="background-color: #f6f6f6">
          <div
            class="
              ml-1
              h-full
              w-8
              duration-300
              flex
              justify-center
              items-center
              cursor-pointer
              rounded
              text-lg
              text-gray-400
              hover:text-black
            "
            @click="onSearch()">
            <icon icon="solar:magnifer-linear" />
          </div>
        </div>
        <div
          class="
            duration-300
            flex
            justify-center
            items-center
            cursor-pointer
            rounded
            text-lg
            text-gray-400
            hover:text-black
          "
          @click="toggleShowTag()">
          <icon icon="solar:tag-linear" />
        </div>
      </div>
      <div
        v-show="isShowTag"
        class="w-10/12 self-center px-5 mb-2">
        <div
          v-for="(tag, i) in tags"
          :key="i"
          class="flex flex-row items-center">
          <input
            v-model="filter.tags"
            type="checkbox"
            :value="tag">
          <span class="text-sm ml-1">
            {{ tag }}
          </span>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return ({
      tags: [
        'รอแอดมิน',
        'เปลี่ยนของ',
        'คอมเพลน'
      ],
      isShowTag: false
    })
  },
  computed: {
    filter: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    onSearch () {
      this.$emit('on-search')
    },
    removeSelectTag (index) {
      this.filter.tags.splice(index, 1)
    },
    toggleShowTag () {
      if (!this.disabled) {
        this.isShowTag = !this.isShowTag
      }
    }
  }
}
</script>
