<template>
  <div class="flex flex-row p-1">
    <div class="w-2/12 flex justify-center items-center">
      <img
        v-if="isNull(item, 'photoUrl')"
        class="rounded-full w-10 h-10"
        :src="item.photoUrl"
        alt="p"
        :style="{
          border: `2px solid ${getBackground(item)}`,
          padding: '2px'
        }"
        @error="(e) => e.target.src = require('@/assets/no-img.png')">
      <div
        v-else
        :style="{
          border: `2px solid ${getBackground(item)}`,
          padding: '2px'
        }"
        class="rounded-full w-10 h-10 bg-gray-300 text-lg flex justify-center items-center">
        <span class="ml-0.5">
          {{ getFirstLetter(item.displayName) }}
        </span>
      </div>
    </div>

    <div class="w-9/12 flex flex-row">
      <img
        class="w-3 h-3 mt-2"
        :src="checkChannel()"
        alt="source">
      <div class="flex flex-col ml-2">
        <span>
          {{ item.displayName }}
        </span>
        <span
          class="text-sm"
          :class="(item.unReadCount > 0) ? 'font-bold' : 'font-light text-gray-500'">
          {{ cutWord(item.lastMessage, 20) }}
        </span>
        <span class="text-sm">
          {{ getTimeDiff(item.lastMessageDate) }}
        </span>
        <div class="flex flex-row flex-wrap mt-1">
          <span
            v-for="(tag, t) in item.tags"
            :key="t"
            class="bg-yellow-200 text-xs m-1 px-1 py-0.5 rounded">
            {{ tag }}
          </span>
        </div>
      </div>
    </div>
    <div class="w-1/12 flex flex-col justify-between mt-1.5">
      <span
        v-if="item.unReadCount > 0"
        class="rounded-full bg-red-500 text-white text-xs w-4 h-4 text-center">
        {{ item.unReadCount }}
      </span>
      <img
        v-if="isNull(item, 'reading')"
        class="w-4 h-4 rounded-full"
        :src="item.reading.picture"
        alt="readBy">
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      currentTime: this.$dayjs()
    }
  },
  mounted () {
    setInterval(() => {
      this.currentTime = this.$dayjs()
    }, 30000)
  },
  methods: {
    getBackground (item) {
      return item?.providerChannel?.color?.background || '#CFCFCF'
    },
    checkChannel () {
      if (this.item.channel === 'LINE') {
        return 'https://line.me/static/115d5539e2d10b8da66d31ce22e6bccd/84249/favicon.png'
      }

      return 'https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/1024px-Facebook_icon_2013.svg.png'
    },
    getTimeDiff (time) {
      const lastTime = this.$dayjs(new Date(time)).add(7, 'h')
      const current = this.currentTime.diff(lastTime)
      const duration = this.$dayjs.duration(current).add(7, 'h')
      const day = duration.format('D')
      const hour = duration.format('H')
      const min = duration.format('mm')

      let format = `${+min} mins ago`

      if (day >= 1) {
        format = `${day} days ago`
      } else if (hour === 1) {
        format = `${hour} hour ago`
      } else if (hour > 0) {
        format = `${hour} hours ago`
      }

      return format
    },
    getFirstLetter (text) {
      const regex = /([A-z])/
      const result = regex.exec(text)

      if (result) {
        return result[0].toUpperCase()
      }

      return 'G'
    },
    isNull (obj, target) {
      return obj && obj[target]
    },
    cutWord (word, limit) {
      if (word.length > limit) {
        return `${word.substr(0, limit)}...`
      }

      return word
    }
  }
}
</script>
